<template>
  <div>
    <div class="left f-hide">
      <el-scrollbar style="height: calc(100%)">
        <div class="outer-box">
          <el-link
            :underline="false"
            @click="$router.push('/certification'), linClick(0)"
            class="two"
            :class="actives == 0 ? 'two-active' : ''"
            >企业类型认证</el-link
          >
          <!-- <el-link
            :underline="false"
            @click="$router.push('/Dinding'), linClick(8)"
            class="two"
            :class="actives == 8 ? 'two-active' : ''"
            >绑定关联销售/燃料公司</el-link
          > -->

          <el-link
            :underline="false"
            @click="$router.push('/Template'), linClick(1)"
            class="two"
            :class="actives == 1 ? 'two-active' : ''"
            >产运需运力合同模板</el-link
          >
          <el-link
            :underline="false"
            @click="$router.push('/Enter'), linClick(2)"
            class="two"
            :class="actives == 2 ? 'two-active' : ''"
            >合同录入</el-link
          >
          <el-link
            :underline="false"
            @click="$router.push('/confirm'), linClick(3)"
            :class="actives == 3 ? 'two-active' : ''"
            class="two"
            >合同确认</el-link
          >
          <el-link
            :underline="false"
            @click="$router.push('/Tovoid'), linClick(4)"
            :class="actives == 4 ? 'two-active' : ''"
            class="two"
            >合同作废</el-link
          >
          <el-link
            :underline="false"
            @click="$router.push('/Query'), linClick(5)"
            :class="actives == 5 ? 'two-active' : ''"
            class="two"
            >合同查询</el-link
          >
          <el-link
            :underline="false"
            @click="$router.push('/Performance'), linClick(6)"
            :class="actives == 6 ? 'two-active' : ''"
            class="two"
            >月度履约数据报送</el-link
          >
          <!-- <el-link
            :class="actives == 7 ? 'two-active' : ''"
            :underline="false"
             @click="$router.push('/Mining'), linClick(7)"
            class="two"
            >产运销数据</el-link
          > -->
        </div>
      </el-scrollbar>

      <!-- <el-tree
        :data="data"
        :props="defaultProps"
        accordion
        @node-click="handleNodeClick"
      >
      </el-tree> -->
    </div>
  </div>
</template>

<script>
import Vue from "vue";
export default {
  data() {
    return {
      data: [
        {
          label: "",
          children: [
            {
              label: "我的银行卡",
              path: "/manage",
              index: 1,
            },
            {
              label: "我的资产",
              path: "/myAssets",
              index: 2,
            },
            {
              label: "交易记录",
              path: "/jiaoyi",
              index: 3,
            },
            {
              label: "账户安全",
              path: "/finance",
              index: 4,
            },
          ],
        },
      ],
      defaultProps: {
        children: "children",
        label: "label",
      },
      actives: 1,
    };
  },
  mounted() {
    this.actives = Vue.ls.get("actives");
  },
  methods: {
    handleNodeClick(data) {
      this.$router.push({
        path: data.path,
      });
      console.log(data);
      this.linClick(data.index);
    },
    linClick(index) {
      this.actives = index;
      Vue.ls.set("actives", index);
    },
  },
};
</script>

<style lang="scss" scoped>
.left {
  width: 186px;
  margin: 20px;
  border: 1px solid #e5e5e5;
  border-top: 0;
  background: #fff;
  text-align: center;
  color: #666666;
  height: 663px;
  overflow: hidden;
  overflow-y: auto;
  .outer-box {
    background-color: #ffffff;
    padding-top: 12px;
    padding-bottom: 10px;
  }
  .outer-box1 {
    background-color: #ffffff;
    padding-top: 0px;

    .two {
      width: 100%;
      font-weight: 600;
    }
  }

  .one {
    font-size: 18px;
    height: 47px;
    font-weight: 550;
    line-height: 47px;
    background: #f3f3f3;
    border-bottom: 1px solid #e5e5e5;
    border-top: 1px solid #e5e5e5;
  }

  .two {
    font-size: 16px;
    display: block;
    height: 50px;
    line-height: 50px;
    margin: 0 auto;
    // width: 136px;
  }
  .outer-box1 {
    padding-top: 0;
    .two {
      font-size: 16px;
      font-weight: 550px;
      font-weight: 550;
    }
  }
  .two-active {
    height: 40px;
    background: linear-gradient(-45deg, #00a0ff, #0a65ff);
    box-shadow: 0px 5px 20px 0px rgba(143, 170, 249, 0.47);
    border-radius: 20px;
    line-height: 40px;
    color: #ffffff !important;
  }
  .three {
    font-size: 14px;
    display: block;
    height: 30px;
    line-height: 30px;
    margin: 0 auto;
    width: 136px;
    color: #666666;
  }
}
::v-deep {
  .el-scrollbar__wrap {
    overflow-x: hidden;
  }
  .el-tree-node__content {
    height: 47px;
    background: #f3f3f3;
    border-top: 1px solid #ccc;
    font-weight: bold;
    .el-tree-node__label {
      font-size: 18px;
      margin: 0 auto;
    }
  }
  .el-tree-node__children {
    .el-tree-node__content {
      background: #fff !important;
      border: none;
      font-weight: normal;
      .el-tree-node__label {
        font-size: 16px;
      }
    }
  }

  .el-tree-node__label {
    // margin-left: 20px;
  }
  .el-tree-node.is-current > .el-tree-node__content {
    background-color: rgba(239, 243, 254, 1) !important;
  }
}
.active {
  background: #fff;
}
</style>
